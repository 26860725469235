import {
  Box,
  Divider,
  Text,
  Heading,
  Skeleton,
  SkeletonText,
  Stack,
  Tooltip,
  Tag,
} from "@chakra-ui/react";
import { EventTypeOut } from "svix";

import usePagination from "@svix/common/hooks/pagination";
import SchemaPreviewer from "@svix/common/widgets/JsonSchema/SchemaPreviewer";
import { isSchemaConfigured } from "@svix/common/widgets/JsonSchema/SchemaPreviewer/utils";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import Pagination from "@svix/common/widgets/Pagination";

import { useAppSelector } from "src/hooks/store";
import EmptyState from "./EmptyState";
import { getSvix } from "../../api";
interface IEventTypePreviewProps {
  eventType: EventTypeOut;
}

function EventTypePreview(props: IEventTypePreviewProps) {
  const { eventType } = props;
  const schema = eventType.schemas?.["1"];

  return (
    <Box as="section" mb={8}>
      <Box mb={2}>
        <Heading as="h2" size="sm" mt={6} mb={2}>
          {eventType.name}
          {eventType.deprecated && (
            <Tooltip
              hasArrow
              label="This event type may be removed in the future and should no longer be used."
            >
              <Tag
                size="sm"
                colorScheme="yellow"
                variant="outline"
                ml={2}
                fontWeight="semibold"
              >
                Deprecated
              </Tag>
            </Tooltip>
          )}
        </Heading>
        <Text variant="caption" whiteSpace="pre-wrap">
          {eventType.description}
        </Text>
      </Box>
      {isSchemaConfigured(schema) && (
        <SchemaPreviewer
          schema={eventType.schemas?.["1"]}
          version={1}
          autogenerateExample
        />
      )}
    </Box>
  );
}

export default function EventTypesScreen() {
  const user = useAppSelector((state) => state.auth.user)!;
  const [eventTypes, eventTypesCtx] = usePagination(
    ["eventCatalog", "eventTypes"],
    async (iterator) => {
      const api = await getSvix();
      return api.eventType.list({ iterator, withContent: true, limit: 50 });
    }
  );
  const { isLoading, pageNumber } = eventTypesCtx;

  // Don't bother showing pagination when there are no events or they fit in one page.
  const hidePagination =
    (eventTypes?.done && pageNumber === 0) || eventTypes?.data.length === 0;

  return (
    <>
      <MetaTitle path={["Event Types", user.app.name]} />
      {isLoading ? (
        <>
          <EventTypeSkeleton />
          <EventTypeSkeleton />
          <EventTypeSkeleton />
        </>
      ) : (
        <>
          {eventTypes?.data.map((eventType) => (
            <EventTypePreview eventType={eventType} key={eventType.name} />
          ))}
          {!hidePagination && (
            <>
              <Divider />
              <Box py={4}>
                <Pagination response={eventTypes} {...eventTypesCtx} />
              </Box>
            </>
          )}
          {eventTypes?.data.length === 0 && <EmptyState />}
        </>
      )}
    </>
  );
}

function EventTypeSkeleton() {
  return (
    <Stack my={6} spacing={4}>
      <Skeleton maxW="20em" height={6} />
      <SkeletonText noOfLines={2} maxW="40em" />
      <Skeleton height="300px" width="100%" />
    </Stack>
  );
}
