import { Suspense, useState } from "react";
import * as React from "react";
import {
  Box,
  Center,
  Grid,
  FormLabel,
  FormControl,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

import { useSearch } from "@svix/common/hooks/search";
import { formatDate, formatTime } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import "./datepicker.css";

import RadioButtons from "./RadioButtons";

const Calendar = React.lazy(() => import("react-calendar"));

interface IDateFilterProps {
  label?: string;
  placeholder?: string;
  control: any;
  name: string;
  required?: boolean;
  quickChoices: DateFilterChoice[];
}

export interface DateFilterChoice {
  value: string;
  label: string;
  getDate: () => Date | undefined;
}

export const NOW_FILTER = { value: "now", label: "Now", getDate: () => new Date() };
export const EARLIER_TODAY_FILTER = {
  value: "5-hours-ago",
  label: "5 hours ago",
  getDate: () => {
    const now = new Date();
    now.setHours(now.getHours() - 5);
    return now;
  },
};
export const YESTERDAY_FILTER = {
  value: "yesterday",
  label: "Yesterday",
  getDate: () => {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    return now;
  },
};

export const LAST_WEEK_FILTER = {
  value: "last-week",
  label: "Last Week",
  getDate: () => {
    const now = new Date();
    now.setDate(now.getDate() - 7);
    return now;
  },
};

export const LAST_MONTH_FILTER = {
  value: "last-month",
  label: "Last Month",
  getDate: () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    return now;
  },
};

export const CUSTOM_DATE_FILTER = {
  value: "custom",
  label: "Custom",
  getDate: () => undefined,
};

export const EARLIEST_DATE_FILTER = {
  value: "earliest",
  label: "Earliest",
  getDate: () => undefined,
};

export function encodeCustomDateFilter(date: Date) {
  return {
    label: "Custom",
    value: `custom-${date.toISOString()}`,
    getDate: () => date,
  };
}

function parseCustomDateFilter(value: string) {
  const timeStr = value.replace("custom-", "");
  const date = new Date(timeStr);
  return {
    label: "Custom",
    value: value,
    getDate: () => date,
  };
}

export function useInitialDateFilter(
  searchParam: string,
  defaultValue: DateFilterChoice
) {
  const savedValue = useSearch(searchParam);

  if (!savedValue) {
    return defaultValue;
  }

  switch (savedValue) {
    case EARLIEST_DATE_FILTER.value:
      return EARLIEST_DATE_FILTER;
    case NOW_FILTER.value:
      return NOW_FILTER;
    case EARLIER_TODAY_FILTER.value:
      return EARLIER_TODAY_FILTER;
    case YESTERDAY_FILTER.value:
      return YESTERDAY_FILTER;
    case LAST_WEEK_FILTER.value:
      return LAST_WEEK_FILTER;
    case LAST_MONTH_FILTER.value:
      return LAST_MONTH_FILTER;
  }

  if (savedValue.startsWith("custom-")) {
    return parseCustomDateFilter(savedValue);
  }

  return CUSTOM_DATE_FILTER;
}

export default function DateFilter(props: IDateFilterProps) {
  const { required, label, control, name, quickChoices } = props;

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  const [selection, setSelection] = useState(value.value);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");

  function updateRadio(value: string) {
    setSelection(value);
    const choice = quickChoices.find((choice) => choice.value === value);

    if (value !== "custom") {
      onChange(choice);
    }
  }

  function applyCustomDateFilter() {
    const compositeDatetime = new Date(selectedDay);
    try {
      if (selectedTime) {
        const [hourStr, minuteStr] = selectedTime.split(":");
        const hour = parseInt(hourStr, 10);
        const minute = parseInt(minuteStr, 10);
        compositeDatetime.setHours(hour);
        compositeDatetime.setMinutes(minute);
      }

      onChange(encodeCustomDateFilter(compositeDatetime));
    } catch (error) {
      // Failed to parse time
    }
  }

  return (
    <FormControl id={name} isRequired={required}>
      <FormLabel>{label}</FormLabel>
      <Box my={4}>
        <RadioButtons options={quickChoices} value={selection} onChange={updateRadio} />
      </Box>

      {selection === "custom" && (
        <Stack>
          <Grid templateColumns="3fr 2fr" gap={2}>
            <Input
              isDisabled
              value={formatDate(selectedDay)}
              size="sm"
              variant="filled"
            />
            <Input
              name="time"
              size="sm"
              placeholder={formatTime(new Date())}
              value={selectedTime}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setSelectedTime(evt.target.value)
              }
              variant="filled"
              onKeyDown={(evt: React.KeyboardEvent) => {
                if (evt.key === "Enter") {
                  evt.preventDefault();
                  applyCustomDateFilter();
                }
              }}
            />
          </Grid>
          <Suspense
            fallback={
              <Center py={8}>
                <LoadingIndicator />
              </Center>
            }
          >
            <Calendar
              calendarType="US"
              value={selectedDay}
              onChange={setSelectedDay}
              minDetail="month"
            />
          </Suspense>
          <Button colorScheme="gray" onClick={applyCustomDateFilter}>
            Apply
          </Button>
        </Stack>
      )}
    </FormControl>
  );
}
