import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Route, Switch, Redirect, RouteProps } from "react-router";
import { useParams } from "react-router-dom";

import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import GlobalErrors from "src/components/GlobalErrors";
import {
  useAppSelector,
  useAppDispatch,
  useAppIdSelector,
  useIsAppAvailable,
  useRegion,
} from "src/hooks/store";
import { setAppId } from "src/store/applications";
import { routeResolver } from "./App";
import AppChrome from "./components/AppChrome";
import useFeatureFlag from "./hooks/featureFlag";
import ActivityScreen from "./screens/Activity";
import AuthedNotFound from "./screens/AuthedNotFound";
import EndpointScreen from "./screens/Endpoint";
import EndpointCreateScreen from "./screens/EndpointCreate";
import EndpointLegacy from "./screens/EndpointLegacy";
import EndpointsScreen from "./screens/Endpoints";
import EndpointTransformationScreen from "./screens/EndpointTransformation";
import EventTypesScreen from "./screens/EventTypes";
import IntegrationsScreen from "./screens/Integrations";
import LoginScreen from "./screens/Login";
import LogsScreen from "./screens/Logs";
import MessageScreen from "./screens/Message";
import ThemeProvider from "./ThemeProvider";

export default function MainRouter() {
  // DO NOT use `useAppSelector` here. `useStateSelector` can be used instead.
  // if adding additional routes/components _outside_ the RequireApplication component, they must include the above warning as well.
  return (
    <Switch>
      <Route path={["/", routeResolver.getRoute("login")]} exact>
        <LoginScreen />
      </Route>

      <Route
        path={[
          routeResolver.getRoute("oauth.slack.authorize"),
          routeResolver.getRoute("oauth.discord.authorize"),
          routeResolver.getRoute("oauth.hubspot.authorize"),
          routeResolver.getRoute("oauth.inngest.webhook"),
        ]}
        exact
      >
        <>Redirecting...</>
      </Route>

      <Route path={routeResolver.getRoute("app")}>
        <RequireApplication>
          <PrivateRoute path="*">
            <ApplicationRoutes />
          </PrivateRoute>
        </RequireApplication>
      </Route>
    </Switch>
  );
}

function ApplicationRoutes() {
  const transformationsEnabled = useAppSelector(
    (state) => state.settings.enableTransformations
  );
  const isIntegrationsMode = useAppSelector(
    (state) => state.embedConfig.isIntegrationsMode
  );
  const integrationsEnabled = useFeatureFlag("integrations") || isIntegrationsMode;
  const legacyEndpointScreen = useFeatureFlag("legacyMessagesView");
  const region = useRegion();
  const showEndpointLegacyScreen = region === "in" || legacyEndpointScreen;

  return (
    <>
      <ThemeProvider>
        <RouteAppChrome>
          <Switch>
            <Route path={routeResolver.getRoute("home")} exact>
              <Redirect to={routeResolver.getRoute("endpoints")} />
            </Route>
            <Route path={routeResolver.getRoute("event-types")}>
              <EventTypesScreen />
            </Route>
            <Route path={routeResolver.getRoute("endpoints.new")}>
              <EndpointCreateScreen />
            </Route>
            {transformationsEnabled && (
              <Route path={routeResolver.getRoute("endpoints._id.transformation")}>
                <EndpointTransformationScreen />
              </Route>
            )}
            <Route path={routeResolver.getRoute("endpoints._id")}>
              {showEndpointLegacyScreen ? <EndpointLegacy /> : <EndpointScreen />}
            </Route>
            <Route path={routeResolver.getRoute("sinks._id")}>
              <EndpointScreen isSink />
            </Route>
            <Route path={routeResolver.getRoute("endpoints")}>
              <EndpointsScreen />
            </Route>
            <Route path={routeResolver.getRoute("messages._id")}>
              <MessageScreen />
            </Route>
            <Route path={routeResolver.getRoute("messages")}>
              <LogsScreen />
            </Route>
            <Route path={routeResolver.getRoute("activity")}>
              <ActivityScreen />
            </Route>
            <Route path="/logs" exact>
              <Redirect to={routeResolver.getRoute("messages")} />
            </Route>
            {integrationsEnabled && (
              <Route path={routeResolver.getRoute("integrations")} exact>
                <IntegrationsScreen />
              </Route>
            )}
            <Route path="*">
              <AuthedNotFound />
            </Route>
          </Switch>
        </RouteAppChrome>
        <GlobalErrors />
      </ThemeProvider>
    </>
  );
}

function RouteAppChrome(props: { children: React.ReactElement }) {
  const isEmbed = useAppSelector((state) => state.embedConfig.isEmbed);
  const noGutters = useAppSelector((state) => state.embedConfig.noGutters) ?? false;
  const isIntegrationMode = useAppSelector(
    (state) => state.embedConfig.isIntegrationsMode
  );

  if (isIntegrationMode) {
    // No App Chrome
    return props.children;
  }

  return (
    <AppChrome isEmbed={isEmbed} noGutters={noGutters}>
      {props.children}
    </AppChrome>
  );
}

function RequireApplication(props: { children: React.ReactElement }) {
  const { appId } = useParams<{ appId: string }>();

  const dispatch = useAppDispatch();
  const stateApplicationId = useAppIdSelector();
  const isAppAvailable = useIsAppAvailable();

  // load application_id from params into state
  useEffect(() => {
    if (appId) {
      Sentry.setUser({ id: appId });
      dispatch(setAppId(appId));
    }
  }, [appId, dispatch]);

  if (stateApplicationId && !isAppAvailable) {
    return (
      <Redirect
        to={{
          pathname: routeResolver.getRoute("login"),
          search: `next=${location.pathname}`,
        }}
      />
    );
  } else if (!stateApplicationId) {
    return <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />;
  }

  return props.children;
}

function PrivateRoute(props: Omit<RouteProps, "render">) {
  const { children, ...rest } = props;

  const user = useAppSelector((state) => state.auth.user);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
