import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Heading,
  Divider,
  Link as StyledLink,
  Image,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { ApiException } from "svix";
import { AuthenticationApi, EnvironmentSettingsApi } from "svix/dist/openapi";

import { SvixRegion } from "@svix/common/constants";
import * as C from "@svix/common/constants";
import { useSearch, useSearchBool } from "@svix/common/hooks/search";
import { logWarning } from "@svix/common/logger";
import { inIframe } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { AppPortalThemeProvider } from "@svix/common/widgets/ThemeProvider";

import { useAppDispatch, useStateSelector, useAppIdSelector } from "src/hooks/store";
import { setAppId, addApplication } from "src/store/applications";
import { enableFlag, isFlag } from "src/store/featureFlags";
import { SupportedIntegrations } from "./Integrations";
import { getSvix } from "../api";
import { queryClient, routeResolver } from "../App";
import svixLogo from "../logo.svg";

export interface UrlLoginData {
  appId: string;
  token?: string;
  oneTimeToken?: string;
  region?: SvixRegion;
  serverUrl?: string;
}

function getErrorMessage(e: Error): string {
  if (e instanceof ApiException) {
    if (e.body?.code === "authentication_failed") {
      return "Invalid or expired session.";
    }
    return e.body.detail;
  }

  return e.message;
}

function useFeatureFlags() {
  const dispatch = useDispatch();
  const flags = useSearch("flags");

  useEffect(() => {
    if (flags) {
      for (const flag of flags.split(",")) {
        if (isFlag(flag)) {
          dispatch(enableFlag(flag));
        }
      }
    }
  }, [flags, dispatch]);
}

export default function LoginScreen() {
  const dispatch = useAppDispatch();
  const appId = useAppIdSelector();
  const applications = useStateSelector((state) => state.applications.apps);

  const baseFontSizeParam = useSearch("fontSize");
  const baseFontSize = useMemo(() => {
    if (baseFontSizeParam) {
      return parseInt(baseFontSizeParam, 10);
    }
    return undefined;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const primaryColor = useSearch("primaryColor");
  const primaryColorLight = useSearch("primaryColorLight");
  const primaryColorDark = useSearch("primaryColorDark");
  const fontFamily = useSearch("fontFamily");
  const logoUrl = useSearch("icon");
  const darkModeParam = useSearch("darkMode");
  const darkModeOverride = darkModeParam === "true" || undefined;
  const isReadOnly = useSearchBool("readOnly");
  const noGutters = useSearchBool("noGutters");
  const next = useSearch("next");
  const mode = useSearch("mode");
  const integrationButtonType = SupportedIntegrations.find(
    (i) => mode === `${i.toLowerCase()}-button`
  );
  const isIntegrationsMode = mode === "integrations" || !!integrationButtonType;

  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const { hash: locationHash } = useLocation();
  const loginData: UrlLoginData | undefined = useMemo(() => {
    const prefix = "#key=";
    if (!locationHash || !locationHash.startsWith(prefix)) {
      return undefined;
    }

    try {
      const parsed = JSON.parse(atob(locationHash.substr(prefix.length)));
      return parsed;
    } catch (e) {
      setError(`Failed processing login token: ${e.message}`);
      return undefined;
    }
  }, [locationHash]);

  useEffect(() => {
    if (!loginData) {
      return;
    }
    setLoading(true);
    (async () => {
      try {
        let token: string;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const loginDataUser = applications[loginData.appId]?.auth.user;
        const serverUrl =
          loginData.serverUrl ?? C.envConfig.getServerUrl(loginData.region ?? "eu");

        // If we are trying to use the same oneTimeToken that we already logged in with, just use the logged in session. We don't want to reject in that case.
        if (loginDataUser && loginData.oneTimeToken === loginDataUser.oneTimeToken) {
          token = loginDataUser.token;
        } else if (loginData.token) {
          // XXX Remove this path in a few days. We should always have a oneTimeToken now.
          token = loginData.token;
        } else if (loginData.oneTimeToken) {
          const svix = getSvix({
            token: "unused",
            serverUrl,
          });
          const api = new AuthenticationApi(svix._configuration);
          const oneTimeTokenOut = await api.v1AuthenticationExchangeOneTimeToken({
            oneTimeTokenIn: { oneTimeToken: loginData.oneTimeToken },
          });
          token = oneTimeTokenOut.token;
        } else {
          throw new Error("Missing token in URL!");
        }
        const svix = getSvix({
          token,
          serverUrl,
        });

        const api = new EnvironmentSettingsApi(svix._configuration);
        const orgSettings = await api.v1EnvironmentGetSettings({});
        await queryClient.prefetchQuery(["orgSettings"], async () => {
          return orgSettings;
        });

        const app = await svix.application.get(loginData.appId);

        dispatch(setAppId(app.id));
        dispatch(
          addApplication({
            auth: {
              user: {
                app,
                token,
                oneTimeToken: loginData.oneTimeToken,
                region: loginData.region ?? "eu",
              },
            },
            embedConfig: {
              primaryColor: paramAsColor(primaryColor),
              primaryColorLight: paramAsColor(primaryColorLight),
              primaryColorDark: paramAsColor(primaryColorDark),
              logoUrl: logoUrl ?? orgSettings.customLogoUrl ?? svixLogo,
              displayName: orgSettings.displayName,
              fontFamily: fontFamily ?? orgSettings.customFontFamily,
              fontFamilyUrl: orgSettings.customFontFamilyUrl,
              baseFontSize,
              noGutters,
              isEmbed: inIframe(),
              isReadOnly,
              isIntegrationsMode,
              integrationButtonType,
              themeOverrides: orgSettings.customThemeOverride,
              stringsOverrides: {
                channelsOne: orgSettings.customStringsOverride?.channelsOne ?? "channel",
                channelsMany:
                  orgSettings.customStringsOverride?.channelsMany ?? "channels",
              },
              hideUseSvixPlay: !orgSettings.showUseSvixPlay,
            },
            settings: {
              darkMode: darkModeOverride ?? false,
              enableTransformations: orgSettings.enableTransformations!,
            },
            serverUrl,
            errors: [],
          })
        );
        setLoggedIn(true);
      } catch (e) {
        setError(getErrorMessage(e));
        setLoading(false);
        if (e.code === 401) {
          logWarning(e);
        }
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useFeatureFlags();

  if (loggedIn && next && isValidNextPath(next)) {
    return <Redirect to={`/${appId}${next}`} />;
  } else if (loggedIn && isIntegrationsMode) {
    return <Redirect to={routeResolver.getRoute("integrations")} />;
  } else if (loggedIn) {
    return <Redirect to={routeResolver.getRoute("app")} />;
  }

  return (
    <AppPortalThemeProvider
      darkMode={darkModeOverride ?? false}
      customFontFamily={fontFamily}
      primaryColorOverride={primaryColor}
    >
      {inIframe() ? (
        <Skeleton
          zIndex={1}
          opacity={1}
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
        />
      ) : (
        <Card margin="auto" padding="2em" maxW="24em">
          <Box textAlign="center">
            <Image
              src={logoUrl ?? svixLogo}
              alt="Logo"
              maxWidth="100px"
              marginX="auto"
              mb={2}
            />
            <Heading as="h2" size="md">
              Svix
            </Heading>
            <Text size="md" my={4}>
              {loading || loginData ? (
                <>Logging you in to the App&nbsp;Portal...</>
              ) : (
                <>
                  <Text>You are not logged in to the App Portal.</Text>
                  <Divider my={2} />
                  <Text fontSize="sm">
                    Organization member?{" "}
                    <StyledLink
                      isExternal
                      ml={1}
                      href={`${
                        C.envConfig.adminDashboardUrl
                      }applications/${appId}?next=${encodeURIComponent(next || "/")}`}
                      color="brand.500"
                      fontWeight="medium"
                    >
                      Log in here
                    </StyledLink>
                  </Text>
                </>
              )}
            </Text>
          </Box>
          {error && (
            <Alert status="error">
              <AlertIcon height="2em" margin="0.5em" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {loading && (
            <div style={{ textAlign: "center" }}>
              <LoadingIndicator />
            </div>
          )}
        </Card>
      )}
    </AppPortalThemeProvider>
  );
}

const WHITELISTED_NEXT_PATHS = [
  "/activity",
  "/endpoints",
  "/event-types",
  "/messages",
  "/integrations",
];

function isValidNextPath(redirect: string): boolean {
  return !!WHITELISTED_NEXT_PATHS.find((x) => redirect.startsWith(x));
}

const hexColorRegex = /^([a-f\d]{3}|[a-f\d]{4}|[a-f\d]{6}|[a-f\d]{8})$/i;

// If the primary color is a hex code, we need to prepend it with a `#`.
// Otherwise, it's a different color format (named, rgb, etc.), and we can use it as-is.
function paramAsColor(param?: string) {
  const col = hexColorRegex.test(param ?? "") ? `#${param}` : param;
  return col;
}
